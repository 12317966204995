import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog20.png"


export default function InsulinResistanceAndDiabetesAreYouAtRisk(){
    return(
        <BlogPostOld
            src={image}
            title={"Chiropractic vs Physical Therapy: What is the Difference?"}
            summary={'Having a clinic with both professions practicing together, we often get this question. It is understandable. From the outside looking in, these are sold as two completely different “services”. '}
            date={"August 16, 2022"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"max-h-full"}
        >
            <>
                <p>
                    However we want to dispel this misconception once and for all. We sat down
                    and talked about it. You may be surprised by the conclusion. If you have
                    some time, watch this. This gives the perspectives of three different
                    clinicians, one being a Doctor of Physical Therapy and two being Doctors of
                    Chiropractic.&nbsp;
                </p>
                <iframe className={"w-full"} width="560" height="315" src="https://www.youtube.com/embed/EQ0uTiorrxU"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <p>
                    Here are the main talking points (I will even put a timestamp them, because
                    I am nice like that):
                </p>
                <ul>
                    <li>
                        PT’s have many specialties that span from sport’s injury, geriatrics to
                        emergency acute care within the hospital system. (0:35)
                    </li>
                    <li>
                        The scope of practice for Physical Therapy and Chiropractic is VERY
                        similar. (1:00)
                    </li>
                    <li>How to find a good doctor whether it is a PT or Chiro. (1:50)</li>
                    <li>Traditional roles of a Chiropractic Clinician (3:10)</li>
                    <li>
                        Good clinicians apply principles and learn from many different fields -
                        PT/Chiro is a profession it is NOT a modality or intervention (3:50)
                    </li>
                    <li>
                        Practitioners are not pigeonholed by their professions, we are rehab
                        professionals (5:45)
                    </li>
                    <li>
                        There is a lot to learn between professions (chiropractic, Physical
                        Therapy, Strength Coaches, Medical Doctors, etc) (6:10)
                    </li>
                    <li>Some benefits of manual therapy (6:44)</li>
                    <li>
                        How the public views the difference Chiropractic and Physical Therapy vs
                        how we do (7:48)
                    </li>
                    <li>
                        Things to look for if you are in pain and looking for a doctor (9:45)
                    </li>
                    <li>Our philosophy on prioritizing the person in front of us (13:44)</li>
                    <li>Expectations for longer standing pain (14:45)</li>
                    <li>Tangent on Chiro’s and x-rays (16:05)</li>
                    <li>Recap (16:55)</li>
                </ul>
                <p>
    <span style={{ color: "#0d0d0d" }}>
      For those who are too cool to watch the video at all… here are s
    </span>
                    <span style={{ color: "#0d0d0d" }}>
      ome of the main takeaways we would like you to take home:
    </span>
                </p>
                <ul>
                    <li>
      <span style={{ color: "#0d0d0d" }}>
        Chiro and PTs should practice very similarly if they are to be
        practicing up to current standards of care.&nbsp;
      </span>
                    </li>
                    <li>
      <span style={{ color: "#0d0d0d" }}>
        Ask a place how long the clinician will be with you one on one before
        going or coming back.
      </span>
                    </li>
                    <li>
      <span style={{ color: "#0d0d0d" }}>
        The relationship with your clinician should be a collaborative one, you
        deserve to be heard and met where you are at.&nbsp;
      </span>
                    </li>
                    <li>
      <span style={{ color: "#0d0d0d" }}>
        Sometimes things do not go as planned and symptoms can increase in
        response to exercise or other modalities. This doesn't mean stop going,
        this means figure out a way to pivot and move in a direction you both
        agree will be in your best interest.&nbsp;
      </span>
                    </li>
                    <li>
      <span style={{ color: "#0d0d0d" }}>
        If a chiropractor insists on doing an x-ray to see alignment and there
        is no risk for bone injuries like fracture... run away (fast and never
        look back).&nbsp;
      </span>
                    </li>
                    <li>
      <span style={{ color: "#0d0d0d" }}>
        If your PT clinic spends 10 minutes with you then puts you on a table
        for ice packs and electrical stim, you aren't doing rehab... you are at
        a med spa.&nbsp;
      </span>
                    </li>
                    <li>
      <span style={{ color: "#0d0d0d" }}>
        At the end of the day the professions are quite similar and should
        always work WITH you so you and the clinician can create.
      </span>
                    </li>
                </ul>
            </>

        </BlogPostOld>

    )
}